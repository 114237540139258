<template>
  <div class="m-0 p-0">
    <div v-if="loading" class="container position-relative min-height-200 loader-position">
      <div class="row m-0 p-0">
        <div class="row m-0 p-0 mt-1">
          <!-- Loader -->
          <Loader :loading="loading" :small="false"></Loader>
        </div>
      </div>
    </div>

    <div v-if="!loading" class="container">
      <div v-if="bookingDetails.booking_type === 'view' || bookingDetails.booking_type === 'reserve'" class="row m-0 p-0">

        <div v-if="!bookingRooms" class="row m-0 p-0 mt-1">
          <ul class="list-unstyled">
            <li class="d-inline-block me-1 summary-list">
              <strong>Price: £</strong> <span class="price-color">{{ convertNumberToCommas(bookingDetails.property.price) }}</span> {{ bookingDetails.property.is_let && bookingDetails.property.property_rent_type ? 'per ' + bookingDetails.property.property_rent_type.type : '' }}
            </li>
            <li v-if="bookingDetails.property.is_let" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Max Guests:  {{ bookingDetails.property.tenants_guests ? bookingDetails.property.tenants_guests : 'N/A' }}
            </li>
            <li v-if="bookingDetails.property.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Tenure:  {{ bookingDetails.property.is_freehold ? 'Freehold' : 'Leasehold' }}
            </li>
            <li v-if="bookingDetails.property.is_sale && bookingDetails.property.is_investment" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Investment
              <font-awesome-icon v-if="bookingDetails.property.is_investment" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li v-if="bookingDetails.property.is_sale && bookingDetails.property.is_cash" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Cash Only
              <font-awesome-icon v-if="bookingDetails.property.is_cash" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li v-if="bookingDetails.property.is_sale && bookingDetails.property.is_auction" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Auction
              <font-awesome-icon v-if="bookingDetails.property.is_auction" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li v-if="bookingDetails.property.is_sale && bookingDetails.property.is_mixed_use" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Mixed-Use
              <font-awesome-icon v-if="bookingDetails.property.is_mixed_use" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
          </ul>

          <ul class="list-unstyled">
            <li v-if="bookingDetails.property.is_let" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Wi-Fi
              <font-awesome-icon v-if="bookingDetails.property.property_details.wifi" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
              {{ bookingDetails.property.property_details.wifi ? (bookingDetails.property.property_details.wifi_paid ? 'Paid' : 'Free') : '' }}
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Pet Friendly
              <font-awesome-icon v-if="bookingDetails.property.is_pet_friendly" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li v-if="bookingDetails.property.is_let" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Smoking Rooms
              <font-awesome-icon v-if="bookingDetails.property.property_details.smoking_rooms" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li v-if="bookingDetails.property.is_let" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Non-smoking Rooms
              <font-awesome-icon v-if="bookingDetails.property.property_details.non_smoking_rooms" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Furnished
              <font-awesome-icon v-if="bookingDetails.property.is_furnished" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Accessible
              <font-awesome-icon v-if="bookingDetails.property.is_accessible" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li v-if="bookingDetails.property.is_let" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Events Allowed
              <font-awesome-icon v-if="bookingDetails.property.events_allowed" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li v-if="bookingDetails.property.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Tenanted
              <font-awesome-icon v-if="bookingDetails.property.is_tenanted" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li v-if="bookingDetails.property.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Shared Ownership
              <font-awesome-icon v-if="bookingDetails.property.is_shared_ownership" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li v-if="bookingDetails.property.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              HMO
              <font-awesome-icon v-if="bookingDetails.property.is_hmo" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li v-if="bookingDetails.property.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Over 60's
              <font-awesome-icon v-if="bookingDetails.property.is_over_60s" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Seashore
              <font-awesome-icon v-if="bookingDetails.property.is_seashore" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Waterfront
              <font-awesome-icon v-if="bookingDetails.property.is_waterfront" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Balcony
              <font-awesome-icon v-if="bookingDetails.property.property_details.balcony" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
          </ul>

          <ul class="list-unstyled">
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Single Room: {{ bookingDetails.property.property_details.single_room }}
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Double Room: {{ bookingDetails.property.property_details.double_room }}
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Studio Flat
              <font-awesome-icon v-if="bookingDetails.property.property_details.studio_room" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Living Room
              <font-awesome-icon v-if="bookingDetails.property.property_details.living_room" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li v-if="bookingDetails.property.is_sale" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Dining Room
              <font-awesome-icon v-if="bookingDetails.property.property_details.dining_room" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
          </ul>

          <ul class="list-unstyled">
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Kitchen
              <font-awesome-icon v-if="bookingDetails.property.property_details.kitchen" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Fridge
              <font-awesome-icon v-if="bookingDetails.property.property_details.fridge" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Freezer
              <font-awesome-icon v-if="bookingDetails.property.property_details.freezer" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Microwave
              <font-awesome-icon v-if="bookingDetails.property.property_details.microwave" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Oven
              <font-awesome-icon v-if="bookingDetails.property.property_details.oven" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Kettle
              <font-awesome-icon v-if="bookingDetails.property.property_details.kettle" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Toaster
              <font-awesome-icon v-if="bookingDetails.property.property_details.toaster" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Coffee
              <font-awesome-icon v-if="bookingDetails.property.property_details.coffee" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
          </ul>

          <ul class="list-unstyled">
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              WC
              <font-awesome-icon v-if="bookingDetails.property.property_details.wc" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Downstairs WC
              <font-awesome-icon v-if="bookingDetails.property.property_details.downstairs_wc" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Shower
              <font-awesome-icon v-if="bookingDetails.property.property_details.shower" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Bath
              <font-awesome-icon v-if="bookingDetails.property.property_details.bath" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
            <li class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Sink
              <font-awesome-icon v-if="bookingDetails.property.property_details.sink" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
            </li>
          </ul>

          <ul class="list-unstyled">
            <li v-if="(bookingDetails.property && bookingDetails.property.is_short_term) || isLongTermRoom()" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Parking
              <font-awesome-icon v-if="bookingDetails.property.property_details.parking" icon="check" class="icon-fa green-text" />
              <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
              {{ bookingDetails.property.property_details.parking ? (bookingDetails.property.property_details.parking_paid ? 'Paid: £ ' + bookingDetails.property.property_details.parking_fee : 'Free') : '' }}
            </li>
            <li v-if="(bookingDetails.property && bookingDetails.property.is_short_term) || isLongTermRoom()" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Parking Spaces: {{ bookingDetails.property.property_details.parking_spaces ? bookingDetails.property.property_details.parking_spaces : 'N/A' }}
            </li>
            <li v-if="bookingDetails.property && !bookingDetails.property.is_short_term && !isLongTermRoom()" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Driveway: {{ bookingDetails.property.property_details.driveway ? bookingDetails.property.property_details.driveway : '0' }}
            </li>
            <li v-if="bookingDetails.property && !bookingDetails.property.is_short_term && !isLongTermRoom()" class="d-inline-block me-1 mt-1 mb-1 summary-list">
              Garage: {{ bookingDetails.property.property_details.garage ? bookingDetails.property.property_details.garage : '0' }}
            </li>
          </ul>
        </div>

        <div class="row m-0 p-0">
          <!-- Room List -->
          <div v-if="bookingRooms && bookingRooms.length > 0" class="col-12 col-sm-12 col-md-8 col-lg-8 m-0 p-0">
            <div class="row m-0 p-0">
              <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                <ul class="list-unstyled">
                  <li class="room-list-header mt-1">
                    <div class="row m-0 p-0">
                      <div class="col-4 col-sm-4 col-md-4 col-lg-4 p-1 options-paragraph">
                        Type
                      </div>
                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1 options-paragraph">
                        Guests
                      </div>
                      <div class="col-4 col-sm-4 col-md-4 col-lg-4 p-1 options-paragraph">
                        Price
                      </div>
                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 options-paragraph">
                        <span data-tooltip="tooltip">
                          <font-awesome-icon icon="paw" class="icon-fa" />
                          <span class="tooltiptext">Pet Friendly</span>
                        </span>
                      </div>
                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 options-paragraph">
                        <span data-tooltip="tooltip">
                          <font-awesome-icon icon="smoking" class="icon-fa" />
                          <span class="tooltiptext">Smoking Allowed</span>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li @click="showRoomDetails(room)" class="room-list mt-1" v-for="room in bookingRooms" v-bind:key="room.id">
                    <div class="row m-0 p-0">
                      <div class="col-4 col-sm-4 col-md-4 col-lg-4 p-1 options-paragraph">
                        <div class="position-relative">
                          <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate ">
                            {{ room.property_room_type.type }}
                          </div>
                        </div>
                      </div>
                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1 options-paragraph">
                        {{ room.guests }}
                      </div>
                      <div class="col-4 col-sm-4 col-md-4 col-lg-4 p-1 options-paragraph">
                        <div class="position-relative">
                          <div class="text-truncate-item no-text-wrap overflow-hidden text-truncate">
                            <font-awesome-icon icon="pound-sign" class="icon-fa me-1" />{{ convertNumberToCommas(room.price) }} per {{ room.property_rent_type.type }}
                          </div>
                        </div>
                      </div>
                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                        <font-awesome-icon v-if="room.pet_friendly_room" icon="check" class="icon-fa green-text" />
                        <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                      </div>
                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                        <font-awesome-icon v-if="room.smoking_room" icon="check" class="icon-fa green-text" />
                        <font-awesome-icon v-else icon="times" class="icon-fa red-text" />
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <!-- Room Details -->
          <div v-if="bookingRooms && bookingRooms.length > 0" class="col-12 col-sm-12 col-md-4 col-lg-4">
            <!-- New Component to display room details data -->
            <div class="room-details mt-1">
              <RoomDetails :formData="roomDetailsData"></RoomDetails>
            </div>
          </div>

        </div>

      </div>

      <!-- Service / Appoint -->
      <div v-if="bookingDetails.booking_type === 'appoint'" class="row m-0 p-0">

        <div class="row m-0 p-0 mt-1">
          <ul class="list-unstyled mt-1 mb-1">
            <li class="d-inline-block me-1 summary-list">
              <strong>Price: £</strong> <span class="price-color">{{ convertNumberToCommas(bookingDetails.service.price) }}</span> {{ bookingDetails.property && bookingDetails.property.property_rent_type ? 'per ' + bookingDetails.property.property_rent_type.type : '' }} {{ bookingDetails.service && bookingDetails.service.service_payment_type ? 'per ' + bookingDetails.service.service_payment_type.type : '' }}
            </li>
            <li class="d-inline-block me-1 summary-list">
              <strong>Service: </strong> {{ bookingDetails.service && bookingDetails.service.contact ? bookingDetails.service.contact.name : '' }}
            </li>
            <li class="d-inline-block me-1 summary-list">
              <strong>Service Provider: </strong> {{ bookingDetails.service && bookingDetails.service.occupation ? bookingDetails.service.occupation.type : '' }}
            </li>
          </ul>

          <ul class="list-unstyled mt-1 mb-1">
            <li class="d-inline-block me-1 summary-list">
              <strong>Client Name: </strong> {{ bookingDetails.service && bookingDetails.service.contact ? bookingDetails.service.contact.name : '' }}
            </li>
            <li class="d-inline-block me-1 summary-list">
              <strong>Client Address: </strong> {{ bookingDetails.service_user_address ? bookingDetails.service_user_address : '' }}
            </li>
          </ul>

          <ul class="list-unstyled mt-1 mb-1">
            <li class="d-inline-block me-1 summary-list">
              <strong>Job Description: </strong>
              <p style="white-space: pre-wrap">
                {{ bookingDetails.service_user_job_description ? bookingDetails.service_user_job_description : '' }}
              </p>
            </li>
          </ul>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios"
import Loader from "../Loader.vue"
import RoomDetails from "../RoomDetails";

export default {
  name: 'DashboardBookingDetails',
  components: {
    'Loader': Loader,
    'RoomDetails': RoomDetails
  },
  props: ['bookingDetails', 'pickedBookingId'],
  data(){
    return {
      bookingRooms: null,
      roomDetailsData: null,
      loading: true,
      processing: true,
      validationErrors: null,
      showError: false,
      validationSuccess: null,
      showSuccess: false
    }
  },
  async mounted() {
  },
  methods: {
    async getBookingRooms(bookingId = null) {
      this.processing = true;
      this.loading = true;
      if (bookingId === null) {
        this.loading = false;
        this.processing = false;
        return;
      }

      let formData = new FormData();

      formData.append('host_id', this.$store.state.auth.user.id);
      formData.append('booking_id', bookingId);

      await axios.post('api/booking-rooms', formData).then(({data}) => {
        this.showError = false;
        this.validationSuccess = null;
        this.validationMessage = '';
        this.validationErrors = '';
        this.bookingRooms = data;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.loading = false;
          this.processing = false;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
        }
      }).finally(() => {
        this.loading = false;
        this.processing = false;
      })
    },
    showRoomDetails(room) {
      this.roomDetailsData = room;
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    isStudioFlat() {
      if (this.bookingDetails.property.property_type_details && this.bookingDetails.property.property_type_details.type_detail && this.bookingDetails.property.property_type_details.type_detail === 'Studio Flat') {
        return true;
      } else {
        return false;
      }
    },
    isLongTermRoom() {
      if (!this.bookingDetails.property.is_short_term && this.bookingDetails.property.property_category.type && this.bookingDetails.property.property_category.type === 'Room') {
        return true;
      } else {
        return false;
      }
    },
  },
  created: function () {

  },
  watch: {
    'pickedBookingId': {
      handler: async function (val) {
        this.loading = true;
        this.processing = true;
        if (this.bookingDetails && this.bookingDetails.property_room_ids && (val == this.bookingDetails.id)) {
          await this.getBookingRooms(this.bookingDetails.id);
        }
        this.loading = false;
        this.processing = false;
      }, deep: true, immediate: true
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.loader-position {
  .loader {
    top: 15vh !important;
  }
}
/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 500px) {

}
@media screen and (min-width: 501px) {

}
@media screen and (min-width: 769px) {

}
@media screen and (min-width: 992px) {

}
@media screen and (min-width: 1200px) {

}
@media screen and (min-width: 1920px) {

}

</style>
