<template>
  <div class="ps-5 pe-5">
    <div class="row">
      <div class="col-12">
        <div class="dashboard-bookings">
<!--          &lt;!&ndash; TEMPORARY UNTIL FINISHED CONSTRUCTING IT &ndash;&gt;-->
<!--          <div class="mt-5">-->
<!--            <div><font-awesome-icon icon="wrench" style="color: blue; font-size: 32px;" /> <font-awesome-icon icon="cogs" style="color: slategrey; font-size: 32px;" /></div>-->
<!--            <p class="mb-0">UNDER CONSTRUCTION</p>-->
<!--            <p class="mb-0">Feel free to use navigation on the left side of the screen</p>-->
<!--          </div>-->
<!--          &lt;!&ndash; TEMPORARY UNTIL FINISHED CONSTRUCTING IT &ndash;&gt;-->

          <div class="h-auto">

            <div class="row h-100 align-items-center pt-3 m-0 pb-4">
              <div class="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-0 m-0">
                <div class="card shadow sm">
                  <div class="card-body p-0" id="scrollTo">

                    <div class="row m-0 p-0">
                      <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                        <ul class="nav nav-tabs justify-content-center">
                          <li class="nav-item">
                            <a @click="getHostCompletedBookings()" id="myCompletedBookingsTabUrl" class="nav-link cursor-pointer">My Completed Bookings</a>
                          </li>
                          <li class="nav-item">
                            <a @click="getHostBookings()" id="myBookingsTabUrl" class="nav-link cursor-pointer active">My Bookings</a>
                          </li>
                          <li class="nav-item">
                            <a @click="getGuestBookings()" id="guestBookingsTabUrl" class="nav-link cursor-pointer">Guests Bookings</a> <!-- data-bs-toggle="tab" -->
                          </li>
                          <li class="nav-item">
                            <a @click="getGuestCompletedBookings()" id="guestCompletedBookingsTabUrl" class="nav-link cursor-pointer">Guest Completed Bookings</a>
                          </li>
                        </ul>

                        <div class="tab-content">

                          <!-- Loading -->
                          <div v-if="loading" class="scroll-width-thin">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 position-relative h-100">
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 booking-loading m-0 p-0 mt-2">
                                <Loader :loading="loading" :small="false"></Loader>
                              </div>
                            </div>
                          </div>

                          <!-- My Completed Bookings Tab -->
                          <div v-if="!loading" class="tab-pane fade scroll-width-thin" id="myCompletedBookings">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 position-relative h-100">
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 host-booking-header m-0 p-0 mt-2">
                                <ul class="list-unstyled m-0 text-start">
                                  <li class="d-inline-block me-1 booking-list-header">
                                    <div class="row m-0 p-0">
                                      <div class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                        <strong>Address</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                        <strong>Type</strong>
                                      </div>
                                      <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Host</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Guests</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Pets</strong>
                                      </div>
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1">
                                        <strong>Term</strong>
                                      </div>
                                      <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Price</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                        <strong>Status</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Actions</strong>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                <div id="completedHostBookingsGroup">
                                  <ul v-if="hostCompletedBookings && hostCompletedBookings.length > 0" class="list-unstyled text-start">
                                    <!-- Loop Host Completed Bookings -->
                                    <li v-for="(hostCompleteBooking, index) in hostCompletedBookings" class="d-inline-block me-1 booking-list">
                                      <!-- @click.prevent="scrollToView('details' + hostCompleteBooking.id)" -->
                                      <button class="booking-btn text-start collapsed" @click="setPickedBookingId(hostCompleteBooking.id)" style="width: 100%;" type="button" data-bs-toggle="collapse" :data-bs-target="'#details' + hostCompleteBooking.id" aria-expanded="false" :aria-controls="'#details' + hostCompleteBooking.id">
                                        <div class="row m-0 p-0">
                                          <div v-if="hostCompleteBooking.booking_type === 'reserve' || hostCompleteBooking.booking_type === 'view'" class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                              {{ hostCompleteBooking.property && hostCompleteBooking.property.address && hostCompleteBooking.property.address.full_address ? hostCompleteBooking.property.address.full_address : '' }}
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                              <span v-if="hostCompleteBooking.property && hostCompleteBooking.property.property_type">
                                              {{ hostCompleteBooking.property && hostCompleteBooking.property.property_type ? hostCompleteBooking.property.property_type.type : '' }}
                                              {{ hostCompleteBooking.property && hostCompleteBooking.property.property_type_details ? hostCompleteBooking.property.property_type_details.type_detail : '' }}
                                              </span>
                                              <span v-else>{{ hostCompleteBooking.property && hostCompleteBooking.property.property_category ? hostCompleteBooking.property.property_category.type : '' }}</span>
                                            </div>
                                          </div>
                                          <div v-if="hostCompleteBooking.booking_type === 'appoint'" class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                              {{ hostCompleteBooking.service_user_address ? hostCompleteBooking.service_user_address : '' }}
                                            </div>
                                          </div>

                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                            <span v-if="hostCompleteBooking.booking_type === 'reserve'">Reservation</span>
                                            <span v-if="hostCompleteBooking.booking_type === 'view'">Viewing</span>
                                            <span v-if="hostCompleteBooking.booking_type === 'appoint'">Service <br/> {{ hostCompleteBooking.service && hostCompleteBooking.service.occupation ? hostCompleteBooking.service.occupation.type : '' }}</span>
                                          </div>
                                          <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">

                                            <span data-tooltip="tooltip" class="m-1">
                                              <button
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#chatModal"
                                                  class="chat-btn p-0 m-0"
                                                  @click="sendModal(hostCompleteBooking, hostCompleteBooking.booking_type, null, 'Chat')"
                                              >
                                                <div class="row m-0 p-0">
                                                  <div class="col-12 m-0 p-0 text-start">
                                                    <div v-if="!hostCompleteBooking.host.documents_user_images">
                                                      <img :src="require(`@/assets/no-user-image.png`)"
                                                           class="img-fluid user-image"
                                                           alt="..."
                                                      >
                                                    </div>
                                                    <div v-else>
                                                      <img :src="`${$s3bucket}${hostCompleteBooking.host.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                                           class="img-fluid user-image"
                                                           alt="..."
                                                      >
                                                    </div>
                                                  </div>
                                                  <div class="col-12 m-0 p-0 text-start">
                                                    <!-- We need to recognise whether its service or property to show correct host/service provider name -->
                                                    <span v-if="hostBooking.booking_type === 'view' || hostBooking.booking_type === 'reserve'" class="user-name">
                                                      {{ hostCompleteBooking.property && hostCompleteBooking.property.contact ? hostCompleteBooking.property.contact.name : '' }}
                                                    </span>
                                                    <span v-if="hostBooking.booking_type === 'appoint'" class="user-name">
                                                      {{ hostCompleteBooking.service && hostCompleteBooking.service.contact ? hostCompleteBooking.service.contact.name : '' }}
                                                    </span>
                                                  </div>
                                                </div>
                                              </button>
                                              <span class="tooltiptext">Send private message</span>
                                            </span>

                                            <!-- Reviews -->
                                            <span data-tooltip="tooltip" class="m-1">
                                              <button
                                                  class="review-btn p-0 m-0"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#reviewsModal"
                                                  @click="sendReviewModal(hostCompleteBooking, null , 'host', hostCompleteBooking.booking_type, 'Reviews')"
                                              >
                                                <font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>
                                                Reviews
                                              </button>
                                              <span class="tooltiptext">Reviews</span>
                                            </span>

                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                            {{ hostCompleteBooking.guests ? hostCompleteBooking.guests : '0' }}
                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                            {{ hostCompleteBooking.pets ? hostCompleteBooking.pets : '0' }}
                                          </div>
                                          <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1">
                                            <span v-if="hostCompleteBooking.booking_start_date" style="white-space: pre-wrap;">{{ hostCompleteBooking.booking_start_date ? 'From: ' + moment.utc(hostCompleteBooking.booking_start_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '' }}
                                              {{ hostCompleteBooking.booking_end_date ? '\nTo: ' + moment.utc(hostCompleteBooking.booking_end_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '' }}
                                            </span>
                                            <span v-if="hostCompleteBooking.viewing_date" style="white-space: pre-wrap;">{{ hostCompleteBooking.viewing_date ? (hostCompleteBooking.booking_type === 'view' ? 'View: ' + moment.utc(hostCompleteBooking.viewing_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : (hostCompleteBooking.booking_type === 'appoint') ? 'Visit: ' + moment.utc(hostCompleteBooking.viewing_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '') : '' }}</span>
                                          </div>
                                          <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                            <strong>£</strong><span class="price-color">{{ hostCompleteBooking.price ? convertNumberToCommas(hostCompleteBooking.price) : '0' }}</span>
                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                            {{ hostCompleteBooking.status ? hostCompleteBooking.status : 'N/A' }}
                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                            <!-- Actions -->
                                            <div v-if="!processActions">
                                              <span data-tooltip="tooltip">
                                                <button v-if="hostCompleteBooking.booking_type === 'reserve' || hostCompleteBooking.booking_type === 'view'" :disabled="processing" @click.stop="openAdDetails(hostCompleteBooking.property_id)" class="details-btn m-1">
                                                  <font-awesome-icon icon="eye" class="icon-fa"/>
                                                </button>
                                                <button v-if="hostCompleteBooking.booking_type === 'appoint'" :disabled="processing"@click.stop="openAdServiceDetails(hostCompleteBooking.service_id)" class="details-btn m-1">
                                                  <font-awesome-icon icon="eye" class="icon-fa"/>
                                                </button>
                                                <span class="tooltiptext">Open related advertise</span>
                                              </span>
                                              <!-- Leave Review -->
                                              <span v-if="hostCompleteBooking.booking_type !== 'view' && hostCompleteBooking.status === 'Completed' && checkReviewDate(hostCompleteBooking)" data-tooltip="tooltip" class="m-1">
                                                <button :disabled="processing"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#addReviewModal"
                                                        class="add-review-btn"
                                                        @click="sendModal(hostCompleteBooking, hostCompleteBooking.booking_type, null, 'Add Review', 'host')"
                                                >
                                                  <font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>
                                                </button>
                                                <span class="tooltiptext">Add Review</span>
                                              </span>
                                            </div>
                                            <div v-if="processActions" class="actions-loader">
                                              <Loader :loading="processActions" :small="true"></Loader>
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                      <div class="booking-details collapse" :id="'details' + hostCompleteBooking.id" data-bs-parent="#completedHostBookingsGroup">
                                        <div class="row m-0 p-0">
                                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-1">
                                            <DashboardBookingDetails :pickedBookingId="pickedBookingId" :bookingDetails="hostCompleteBooking"></DashboardBookingDetails>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                  <!-- No Results -->
                                  <ul v-else class="list-unstyled text-center">
                                    <li class="d-inline-block me-1 booking-list">
                                      No results to show
                                    </li>
                                  </ul>
                                  </div>
                              </div>
                            </div>
                          </div>

                          <!-- My Bookings Tab -->
                          <div v-if="!loading" class="tab-pane fade show active scroll-width-thin" id="myBookings">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 position-relative h-100">
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 host-booking-header m-0 p-0 mt-2">
                                <ul class="list-unstyled m-0 text-start">
                                  <li class="d-inline-block me-1 booking-list-header">
                                    <div class="row m-0 p-0">
                                      <div class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                        <strong>Address</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                        <strong>Type</strong>
                                      </div>
                                      <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Host</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Guests</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Pets</strong>
                                      </div>
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1">
                                        <strong>Term</strong>
                                      </div>
                                      <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Price</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                        <strong>Status</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Actions</strong>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                <div id="hostBookingsGroup">
                                  <ul v-if="hostBookings && hostBookings.length > 0" class="list-unstyled text-start">
                                    <!-- Loop Host Bookings -->
                                    <li v-for="(hostBooking, index) in hostBookings" :class="hostBooking.is_issue_raised ? 'd-inline-block me-1 booking-list booking-list-raised-issue' : 'd-inline-block me-1 booking-list'">
                                      <!-- @click.prevent="scrollToView('details' + hostBooking.id)" -->
                                      <button class="booking-btn text-start collapsed" @click="setPickedBookingId(hostBooking.id)" style="width: 100%;" type="button" data-bs-toggle="collapse" :data-bs-target="'#details' + hostBooking.id" aria-expanded="false" :aria-controls="'#details' + hostBooking.id">
                                        <div class="row m-0 p-0">
                                          <div v-if="hostBooking.booking_type === 'reserve' || hostBooking.booking_type === 'view'" class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                              {{ hostBooking.property && hostBooking.property.address && hostBooking.property.address.full_address ? hostBooking.property.address.full_address : '' }}
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                              <span v-if="hostBooking.property && hostBooking.property.property_type">
                                              {{ hostBooking.property && hostBooking.property.property_type ? hostBooking.property.property_type.type : '' }}
                                              {{ hostBooking.property && hostBooking.property.property_type_details ? hostBooking.property.property_type_details.type_detail : '' }}
                                              </span>
                                              <span v-else>{{ hostBooking.property && hostBooking.property.property_category ? hostBooking.property.property_category.type : '' }}</span>
                                            </div>
                                          </div>
                                          <div v-if="hostBooking.booking_type === 'appoint'" class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                              {{ hostBooking.service_user_address ? hostBooking.service_user_address : '' }}
                                            </div>
                                          </div>

                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                            <span v-if="hostBooking.booking_type === 'reserve'">Reservation</span>
                                            <span v-if="hostBooking.booking_type === 'view'">Viewing</span>
                                            <span v-if="hostBooking.booking_type === 'appoint'">Service <br/> {{ hostBooking.service && hostBooking.service.occupation ? hostBooking.service.occupation.type : '' }}</span>
                                          </div>
                                          <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">

                                            <span data-tooltip="tooltip" class="m-1">
                                              <button
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#chatModal"
                                                  class="chat-btn p-0 m-0"
                                                  @click="sendModal(hostBooking, hostBooking.booking_type, null, 'Chat')"
                                              >
                                                <div class="row m-0 p-0">
                                                  <div class="col-12 m-0 p-0 text-start">
                                                    <div v-if="!hostBooking.host.documents_user_images">
                                                      <img :src="require(`@/assets/no-user-image.png`)"
                                                           class="img-fluid user-image"
                                                           alt="..."
                                                      >
                                                    </div>
                                                    <div v-else>
                                                      <img :src="`${$s3bucket}${hostBooking.host.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                                           class="img-fluid user-image"
                                                           alt="..."
                                                      >
                                                    </div>
                                                  </div>
                                                  <div class="col-12 m-0 p-0 text-start">
                                                    <!-- We need to recognise whether its service or property to show correct host/service provider name -->
                                                    <span v-if="hostBooking.booking_type === 'view' || hostBooking.booking_type === 'reserve'" class="user-name">
                                                      {{ hostBooking.property && hostBooking.property.contact ? hostBooking.property.contact.name : '' }}
                                                    </span>
                                                    <span v-if="hostBooking.booking_type === 'appoint'" class="user-name">
                                                      {{ hostBooking.service && hostBooking.service.contact ? hostBooking.service.contact.name : '' }}
                                                    </span>
                                                  </div>
                                                </div>
                                              </button>
                                              <span class="tooltiptext">Send private message</span>
                                            </span>

                                            <!-- Reviews -->
                                            <span data-tooltip="tooltip" class="m-1">
                                              <button
                                                  class="review-btn p-0 m-0"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#reviewsModal"
                                                  @click="sendReviewModal(hostBooking, null , 'host', hostBooking.booking_type, 'Reviews')"
                                              >
                                                <font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>
                                                Reviews
                                              </button>
                                              <span class="tooltiptext">Reviews</span>
                                            </span>

                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                            {{ hostBooking.guests ? hostBooking.guests : '0' }}
                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                            {{ hostBooking.pets ? hostBooking.pets : '0' }}
                                          </div>
                                          <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1">
                                            <span v-if="hostBooking.booking_start_date" style="white-space: pre-wrap;">{{ hostBooking.booking_start_date ? 'From: ' + moment.utc(hostBooking.booking_start_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '' }}
                                              {{ hostBooking.booking_end_date ? '\nTo: ' + moment.utc(hostBooking.booking_end_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '' }}
                                            </span>
                                            <span v-if="hostBooking.viewing_date" style="white-space: pre-wrap;">{{ hostBooking.viewing_date ? (hostBooking.booking_type === 'view' ? 'View: ' + moment.utc(hostBooking.viewing_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : (hostBooking.booking_type === 'appoint') ? 'Visit: ' + moment.utc(hostBooking.viewing_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '') : '' }}</span>
                                          </div>
                                          <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                            <strong>£</strong><span class="price-color">{{ hostBooking.price ? convertNumberToCommas(hostBooking.price) : '0' }}</span>
                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                            {{ hostBooking.status ? hostBooking.status : 'N/A' }}
                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                            <!-- Actions -->
                                            <div v-if="!processActions">
                                              <span data-tooltip="tooltip">
                                                <button v-if="hostBooking.booking_type === 'reserve' || hostBooking.booking_type === 'view'" :disabled="processing" @click.stop="openAdDetails(hostBooking.property_id)" class="details-btn m-1">
                                                  <font-awesome-icon icon="eye" class="icon-fa"/>
                                                </button>
                                                <button v-if="hostBooking.booking_type === 'appoint'" :disabled="processing"@click.stop="openAdServiceDetails(hostBooking.service_id)" class="details-btn m-1">
                                                  <font-awesome-icon icon="eye" class="icon-fa"/>
                                                </button>
                                                <span class="tooltiptext">Open related advertise</span>
                                              </span>
    <!--                                          <span data-tooltip="tooltip" class="m-1">-->
    <!--                                            <button :disabled="processing" @click="confirmBooking(hostBooking)" class="accept-btn">-->
    <!--                                              <font-awesome-icon icon="check" class="icon-fa" />-->
    <!--                                            </button>-->
    <!--                                            <span class="tooltiptext">Confirm Booking</span>-->
    <!--                                          </span>-->
    <!--                                          <span data-tooltip="tooltip" class="m-1">-->
    <!--                                            <button :disabled="processing" @click="declineBooking(hostBooking)" class="decline-btn">-->
    <!--                                            <font-awesome-icon icon="times" class="icon-fa" />-->
    <!--                                          </button>-->
    <!--                                            <span class="tooltiptext">Decline Booking</span>-->
    <!--                                          </span>-->
                                              <!-- Raise Issue Button visible only if (Status Paid, Reservation and from Booking Start Date till Booking End Date + 3 days) OR if (Status Confirmed, Viewing/Appointment and Viewing Date -1 Day till Viewing Date + 3 Days) -->
                                              <span v-if="showRaiseIssueButton(hostBooking)" data-tooltip="tooltip" class="m-1">
                                                <button :disabled="processing"
                                                        @click="sendRaiseIssueModal(hostBooking, hostBooking.booking_type, 'Raise Issue')"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#raiseIssueModal"
                                                        class="raise-issue-btn"
                                                >
                                                  <font-awesome-icon icon="exclamation" class="icon-fa" />
                                                </button>
                                                <span class="tooltiptext">Raise Issue</span>
                                              </span>
                                              <!-- Payment Button visible only if Status Confirmed and Reservation -->
                                              <span v-if="hostBooking.status === 'Confirmed' && hostBooking.booking_type === 'reserve'" data-tooltip="tooltip" class="m-1">
                                                <router-link :to="{ name: 'booking-payment', params: { token: hostBooking.invoice.invoice_token }}" class="text-decoration-none">
                                                  <button :disabled="processing"
                                                          class="pay-for-booking-btn"
                                                  >
                                                    <font-awesome-icon icon="coins" class="icon-fa" />
                                                  </button>
                                                </router-link>
                                                <span class="tooltiptext">Pay for Booking</span>
                                              </span>
                                              <!-- Cancel Should be visible for user, not host, eventually for host as well, but after it was accepted -->
                                              <span v-if="hostBooking.status !== 'Declined' && hostBooking.status !== 'Cancelled' && hostBooking.status !== 'Auto-Cancelled' && hostBooking.status !== 'Raised Issue' && checkCancelDate(hostBooking)" data-tooltip="tooltip" class="m-1">
                                                <button :disabled="processing"
                                                        @click="cancelBooking(hostBooking)"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#confirmationModal"
                                                        class="cancel-btn"
                                                >
                                                  <font-awesome-icon icon="times" class="icon-fa" />
                                                </button>
                                                <span class="tooltiptext">Cancel Booking</span>
                                              </span>
                                            </div>
                                            <div v-if="processActions" class="actions-loader">
                                              <Loader :loading="processActions" :small="true"></Loader>
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                      <div class="booking-details collapse" :id="'details' + hostBooking.id" data-bs-parent="#hostBookingsGroup">
                                        <div class="row m-0 p-0">
                                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-1">
                                            <DashboardBookingDetails :pickedBookingId="pickedBookingId" :bookingDetails="hostBooking"></DashboardBookingDetails>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                  <!-- No Results -->
                                  <ul v-else class="list-unstyled text-center">
                                    <li class="d-inline-block me-1 booking-list">
                                      No results to show
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Guest Bookings Tab -->
                          <div v-if="!loading" class="tab-pane fade scroll-width-thin" id="guestBookings">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 position-relative h-100">
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 host-booking-header m-0 p-0 mt-2">
                                <ul class="list-unstyled m-0 text-start">
                                  <li class="d-inline-block me-1 booking-list-header">
                                    <div class="row m-0 p-0">
                                      <div class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                        <strong>Address</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                        <strong>Type</strong>
                                      </div>
                                      <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Main Guest</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Guests</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Pets</strong>
                                      </div>
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1">
                                        <strong>Term</strong>
                                      </div>
                                      <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Price</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                        <strong>Status</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Actions</strong>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                <div id="guestBookingsGroup">
                                  <ul v-if="guestBookings && guestBookings.length > 0" class="list-unstyled text-start">
                                    <!-- Loop Guest Bookings -->
                                    <li v-for="(guestBooking, index) in guestBookings" :class="guestBooking.is_issue_raised ? 'd-inline-block me-1 booking-list booking-list-raised-issue' : 'd-inline-block me-1 booking-list'">
                                      <!-- @click.prevent="scrollToView('details' + guestBooking.id)" -->
                                      <button class="booking-btn text-start collapsed" @click="setPickedBookingId(guestBooking.id)" style="width: 100%;" type="button" data-bs-toggle="collapse" :data-bs-target="'#details' + guestBooking.id" aria-expanded="false" :aria-controls="'#details' + guestBooking.id">
                                        <div class="row m-0 p-0">
                                          <div v-if="guestBooking.booking_type === 'reserve' || guestBooking.booking_type === 'view'" class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                              {{ guestBooking.property && guestBooking.property.address && guestBooking.property.address.full_address ? guestBooking.property.address.full_address : '' }}
                                            </div>
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                              <span v-if="guestBooking.property && guestBooking.property.property_type">
                                              {{ guestBooking.property && guestBooking.property.property_type ? guestBooking.property.property_type.type : '' }}
                                              {{ guestBooking.property && guestBooking.property.property_type_details ? guestBooking.property.property_type_details.type_detail : '' }}
                                              </span>
                                              <span v-else>{{ guestBooking.property && guestBooking.property.property_category ? guestBooking.property.property_category.type : '' }}</span>
                                            </div>
                                          </div>
                                          <div v-if="guestBooking.booking_type === 'appoint'" class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                              {{ guestBooking.service_user_address ? guestBooking.service_user_address : '' }}
                                            </div>
                                          </div>

                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                            <span v-if="guestBooking.booking_type === 'reserve'">Reservation</span>
                                            <span v-if="guestBooking.booking_type === 'view'">Viewing</span>
                                            <span v-if="guestBooking.booking_type === 'appoint'">Service <br/> {{ guestBooking.service && guestBooking.service.occupation ? guestBooking.service.occupation.type : '' }}</span>
                                          </div>
                                          <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">

                                            <span data-tooltip="tooltip" class="m-1">
                                              <button
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#chatModal"
                                                  class="chat-btn p-0 m-0"
                                                  @click="sendModal(guestBooking, guestBooking.booking_type, null, 'Chat')"
                                              >
                                                <div class="row m-0 p-0">
                                                  <div class="col-12 m-0 p-0 text-start">
                                                    <div v-if="!guestBooking.guest.documents_user_images">
                                                      <img :src="require(`@/assets/no-user-image.png`)"
                                                           class="img-fluid user-image"
                                                           alt="..."
                                                      >
                                                    </div>
                                                    <div v-else>
                                                      <img :src="`${$s3bucket}${guestBooking.guest.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                                           class="img-fluid user-image"
                                                           alt="..."
                                                      >
                                                    </div>
                                                  </div>
                                                  <div class="col-12 m-0 p-0 text-start">
                                                    <span class="user-name">
                                                      {{ guestBooking.guest && guestBooking.guest.contact ? guestBooking.guest.contact.name : '' }}
                                                    </span>
                                                  </div>
                                                </div>
                                              </button>
                                              <span class="tooltiptext">Send private message</span>
                                            </span>

                                            <!-- Reviews -->
                                            <span data-tooltip="tooltip" class="m-1">
                                              <button
                                                  class="review-btn p-0 m-0"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#reviewsModal"
                                                  @click="sendReviewModal(guestBooking, guestBooking.user_id , 'guest', guestBooking.booking_type, 'Reviews')"
                                              >
                                                <font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>
                                                Reviews
                                              </button>
                                              <span class="tooltiptext">Reviews</span>
                                            </span>

                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                            {{ guestBooking.guests ? guestBooking.guests : '0' }}
                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                            {{ guestBooking.pets ? guestBooking.pets : '0' }}
                                          </div>
                                          <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1">
                                            <span v-if="guestBooking.booking_start_date" style="white-space: pre-wrap;">{{ guestBooking.booking_start_date ? 'From: ' + moment.utc(guestBooking.booking_start_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '' }}
                                              {{ guestBooking.booking_end_date ? '\nTo: ' + moment.utc(guestBooking.booking_end_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '' }}
                                            </span>
                                            <span v-if="guestBooking.viewing_date" style="white-space: pre-wrap;">{{ guestBooking.viewing_date ? (guestBooking.booking_type === 'view' ? 'View: ' + moment.utc(guestBooking.viewing_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : (guestBooking.booking_type === 'appoint') ? 'Visit: ' + moment.utc(guestBooking.viewing_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '') : '' }}</span>
                                          </div>
                                          <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                            <strong>£</strong><span class="price-color">{{ guestBooking.price ? convertNumberToCommas(guestBooking.price) : '0' }}</span>
                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                            {{ guestBooking.status ? guestBooking.status : 'N/A' }}
                                          </div>
                                          <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                            <!-- Actions -->
                                            <div v-if="!processActions">
                                              <span data-tooltip="tooltip">
                                                <button v-if="guestBooking.booking_type === 'reserve' || guestBooking.booking_type === 'view'" :disabled="processing" @click.stop="openAdDetails(guestBooking.property_id)" class="details-btn m-1">
                                                  <font-awesome-icon icon="eye" class="icon-fa"/>
                                                </button>
                                                <button v-if="guestBooking.booking_type === 'appoint'" :disabled="processing"@click.stop="openAdServiceDetails(guestBooking.service_id)" class="details-btn m-1">
                                                  <font-awesome-icon icon="eye" class="icon-fa"/>
                                                </button>
                                                <span class="tooltiptext">Open related advertise</span>
                                              </span>
                                              <span v-if="guestBooking.status === 'Awaiting Confirmation'" data-tooltip="tooltip">
                                                <button :disabled="processing"
                                                        @click="confirmBooking(guestBooking, false)"
                                                        class="accept-btn m-1"
                                                >
                                                  <font-awesome-icon icon="check" class="icon-fa" />
                                                </button>
<!--                                                data-bs-toggle="modal"-->
<!--                                                        data-bs-target="#confirmationModal"-->
                                                <span class="tooltiptext">Confirm Booking</span>
                                              </span>
<!--                                              <ConfirmationModal :ref="'confirmationModal-' + guestBooking.id"></ConfirmationModal>-->
                                              <span v-if="guestBooking.status === 'Awaiting Confirmation'" data-tooltip="tooltip">
                                                <button :disabled="processing"
                                                        @click="declineBooking(guestBooking, false)"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#confirmationModal"
                                                        class="decline-btn m-1"
                                                >
                                                <font-awesome-icon icon="times" class="icon-fa" />
                                              </button>
                                                <span class="tooltiptext">Decline Booking</span>
                                              </span>
                                              <!-- Cancel Should be visible for user, not host, eventually for host as well, but after it was accepted -->
                                              <span v-if="guestBooking.status !== 'Awaiting Confirmation' && guestBooking.status !== 'Declined' && guestBooking.status !== 'Cancelled' && guestBooking.status !== 'Auto-Cancelled' && guestBooking.status !== 'Raised Issue' && checkCancelDate(guestBooking)" data-tooltip="tooltip">
                                                <button :disabled="processing"
                                                        @click="cancelBooking(guestBooking, false)"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#confirmationModal"
                                                        class="cancel-btn m-1"
                                                >
                                                  <font-awesome-icon icon="times" class="icon-fa" />
                                                </button>
                                                <span class="tooltiptext">Cancel Booking</span>
                                              </span>
                                            </div>
                                            <div v-if="processActions" class="actions-loader">
                                              <Loader :loading="processActions" :small="true"></Loader>
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                      <div class="booking-details collapse" :id="'details' + guestBooking.id" data-bs-parent="#guestBookingsGroup">
                                        <div class="row m-0 p-0">
                                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-1">
                                            <DashboardBookingDetails :pickedBookingId="pickedBookingId" :bookingDetails="guestBooking"></DashboardBookingDetails>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                  <!-- No Results -->
                                  <ul v-else class="list-unstyled text-center">
                                    <li class="d-inline-block me-1 booking-list">
                                      No results to show
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Completed Guest Bookings Tab -->
                          <div v-if="!loading" class="tab-pane fade scroll-width-thin" id="guestCompletedBookings">
                            <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 position-relative h-100">
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 host-booking-header m-0 p-0 mt-2">
                                <ul class="list-unstyled m-0 text-start">
                                  <li class="d-inline-block me-1 booking-list-header">
                                    <div class="row m-0 p-0">
                                      <div class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                        <strong>Address</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                        <strong>Type</strong>
                                      </div>
                                      <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Main Guest</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Guests</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Pets</strong>
                                      </div>
                                      <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1">
                                        <strong>Term</strong>
                                      </div>
                                      <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Price</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                        <strong>Status</strong>
                                      </div>
                                      <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                        <strong>Actions</strong>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                <div id="completedGuestBookingsGroup">
                                <ul v-if="guestCompletedBookings && guestCompletedBookings.length > 0" class="list-unstyled text-start">
                                  <!-- Loop Completed Guest Bookings -->
                                  <li v-for="(guestCompleteBooking, index) in guestCompletedBookings" class="d-inline-block me-1 booking-list">
                                    <!-- @click.prevent="scrollToView('details' + guestCompleteBooking.id)" -->
                                    <button class="booking-btn text-start collapsed" @click="setPickedBookingId(guestCompleteBooking.id)" style="width: 100%;" type="button" data-bs-toggle="collapse" :data-bs-target="'#details' + guestCompleteBooking.id" aria-expanded="false" :aria-controls="'#details' + guestCompleteBooking.id">
                                      <div class="row m-0 p-0">
                                        <div v-if="guestCompleteBooking.booking_type === 'reserve' || guestCompleteBooking.booking_type === 'view'" class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                            {{ guestCompleteBooking.property && guestCompleteBooking.property.address && guestCompleteBooking.property.address.full_address ? guestCompleteBooking.property.address.full_address : '' }}
                                          </div>
                                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                              <span v-if="guestCompleteBooking.property && guestCompleteBooking.property.property_type">
                                              {{ guestCompleteBooking.property && guestCompleteBooking.property.property_type ? guestCompleteBooking.property.property_type.type : '' }}
                                              {{ guestCompleteBooking.property && guestCompleteBooking.property.property_type_details ? guestCompleteBooking.property.property_type_details.type_detail : '' }}
                                              </span>
                                            <span v-else>{{ guestCompleteBooking.property && guestCompleteBooking.property.property_category ? guestCompleteBooking.property.property_category.type : '' }}</span>
                                          </div>
                                        </div>
                                        <div v-if="guestCompleteBooking.booking_type === 'appoint'" class="col-3 col-sm-3 col-md-3 col-lg-3 p-1">
                                          <div class="col-12 col-sm-12 col-md-12 col-lg-12 m-0 p-0">
                                            {{ guestCompleteBooking.service_user_address ? guestCompleteBooking.service_user_address : '' }}
                                          </div>
                                        </div>

                                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                          <span v-if="guestCompleteBooking.booking_type === 'reserve'">Reservation</span>
                                          <span v-if="guestCompleteBooking.booking_type === 'view'">Viewing</span>
                                          <span v-if="guestCompleteBooking.booking_type === 'appoint'">Service <br/> {{ guestCompleteBooking.service && guestCompleteBooking.service.occupation ? guestCompleteBooking.service.occupation.type : '' }}</span>
                                        </div>
                                        <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">

                                          <span data-tooltip="tooltip" class="m-1">
                                            <button
                                                data-bs-toggle="modal"
                                                data-bs-target="#chatModal"
                                                class="chat-btn p-0 m-0"
                                                @click="sendModal(guestCompleteBooking, guestCompleteBooking.booking_type, null, 'Chat')"
                                            >
                                              <div class="row m-0 p-0">
                                                <div class="col-12 m-0 p-0 text-start">
                                                  <div v-if="!guestCompleteBooking.guest.documents_user_images">
                                                    <img :src="require(`@/assets/no-user-image.png`)"
                                                         class="img-fluid user-image"
                                                         alt="..."
                                                    >
                                                  </div>
                                                  <div v-else>
                                                    <img :src="`${$s3bucket}${guestCompleteBooking.guest.documents_user_images.file_path}`" @error="setUserDefaultImg"
                                                         class="img-fluid user-image"
                                                         alt="..."
                                                    >
                                                  </div>
                                                </div>
                                                <div class="col-12 m-0 p-0 text-start">
                                                    <span class="user-name">
                                                      {{ guestCompleteBooking.guest && guestCompleteBooking.guest.contact ? guestCompleteBooking.guest.contact.name : '' }}
                                                    </span>
                                                </div>
                                              </div>
                                            </button>
                                            <span class="tooltiptext">Send private message</span>
                                          </span>

                                          <!-- Reviews -->
                                          <span data-tooltip="tooltip" class="m-1">
                                              <button
                                                  class="review-btn p-0 m-0"
                                                  data-bs-toggle="modal"
                                                  data-bs-target="#reviewsModal"
                                                  @click="sendReviewModal(guestCompleteBooking, guestCompleteBooking.user_id , 'guest', guestCompleteBooking.booking_type, 'Reviews')"
                                              >
                                                <font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>
                                                Reviews
                                              </button>
                                              <span class="tooltiptext">Reviews</span>
                                            </span>

                                        </div>
                                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                          {{ guestCompleteBooking.guests ? guestCompleteBooking.guests : '0' }}
                                        </div>
                                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                          {{ guestCompleteBooking.pets ? guestCompleteBooking.pets : '0' }}
                                        </div>
                                        <div class="col-2 col-sm-2 col-md-2 col-lg-2 p-1">
                                          <span v-if="guestCompleteBooking.booking_start_date" style="white-space: pre-wrap;">{{ guestCompleteBooking.booking_start_date ? 'From: ' + moment.utc(guestCompleteBooking.booking_start_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '' }}
                                            {{ guestCompleteBooking.booking_end_date ? '\nTo: ' + moment.utc(guestCompleteBooking.booking_end_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '' }}
                                          </span>
                                          <span v-if="guestCompleteBooking.viewing_date" style="white-space: pre-wrap;">{{ guestCompleteBooking.viewing_date ? (guestCompleteBooking.booking_type === 'view' ? 'View: ' + moment.utc(guestCompleteBooking.viewing_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : (guestCompleteBooking.booking_type === 'appoint') ? 'Visit: ' + moment.utc(guestCompleteBooking.viewing_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY HH:mm') : '') : '' }}</span>
                                        </div>
                                        <div class="col-2 col-sm-1 col-md-1 col-lg-1 p-1">
                                          <strong>£</strong><span class="price-color">{{ guestCompleteBooking.price ? convertNumberToCommas(guestCompleteBooking.price) : '0' }}</span>
                                        </div>
                                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1 d-none d-sm-block">
                                          {{ guestCompleteBooking.status ? guestCompleteBooking.status : 'N/A' }}
                                        </div>
                                        <div class="col-1 col-sm-1 col-md-1 col-lg-1 p-1">
                                          <!-- Actions -->
                                          <div v-if="!processActions">
                                            <span data-tooltip="tooltip">
                                                <button v-if="guestCompleteBooking.booking_type === 'reserve' || guestCompleteBooking.booking_type === 'view'" :disabled="processing" @click.stop="openAdDetails(guestCompleteBooking.property_id)" class="details-btn m-1">
                                                  <font-awesome-icon icon="eye" class="icon-fa"/>
                                                </button>
                                                <button v-if="guestCompleteBooking.booking_type === 'appoint'" :disabled="processing"@click.stop="openAdServiceDetails(guestCompleteBooking.service_id)" class="details-btn m-1">
                                                  <font-awesome-icon icon="eye" class="icon-fa"/>
                                                </button>
                                                <span class="tooltiptext">Open related advertise</span>
                                            </span>
                                            <!-- Leave Review -->
                                            <span v-if="guestCompleteBooking.booking_type !== 'view' && guestCompleteBooking.status === 'Completed' && checkReviewDate(guestCompleteBooking)" data-tooltip="tooltip" class="m-1">
                                                <button :disabled="processing"
                                                        data-bs-toggle="modal"
                                                        data-bs-target="#addReviewModal"
                                                        class="add-review-btn"
                                                        @click="sendModal(guestCompleteBooking, guestCompleteBooking.booking_type, null, 'Add Review', 'guest')"
                                                >
                                                  <font-awesome-icon icon="star" class="star-icon" style="color: yellow;"/>
                                                </button>
                                                <span class="tooltiptext">Add Review</span>
                                              </span>
                                          </div>
                                          <div v-if="processActions" class="actions-loader">
                                            <Loader :loading="processActions" :small="true"></Loader>
                                          </div>
                                        </div>
                                      </div>
                                    </button>
                                    <div class="booking-details collapse" :id="'details' + guestCompleteBooking.id" data-bs-parent="#completedGuestBookingsGroup">
                                      <div class="row m-0 p-0">
                                        <div class="col-12 col-sm-12 col-md-12 col-lg-12 p-1">
                                          <DashboardBookingDetails :pickedBookingId="pickedBookingId" :bookingDetails="guestCompleteBooking"></DashboardBookingDetails>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                <!-- No Results -->
                                <ul v-else class="list-unstyled text-center">
                                  <li class="d-inline-block me-1 booking-list">
                                    No results to show
                                  </li>
                                </ul>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <!-- Pagination -->
                        <ul class="pagination justify-content-center" v-if="pagination && !loading">
                          <li class="page-item" :class="{disabled: metaData.current_page === 1}">
                            <a class="page-link" @click="previous"><span aria-hidden="true">&laquo;</span></a>
                          </li>
                          <!-- HERE I NEED TO PROVIDE MY OWN CALCULATED number of pages? instead of last_page metaData.last_page -->
                          <li
                              v-for="number in paginationData"
                              class="page-item"
                              :class="{active: metaData.current_page === number}"
                          >
                            <a class="page-link" @click="navigateTo(null, number, paginationType)">{{ number }}</a>
                          </li>
                          <li class="page-item" :class="{disabled: metaData.current_page === metaData.last_page}">
                            <a class="page-link" @click="next"><span aria-hidden="true">&raquo;</span></a>
                          </li>
                        </ul>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

<!--          <div class="h-auto">-->

<!--            <div class="row h-100 align-items-center pt-4 pb-4">-->
<!--              <div class="col-12 col-lg-8 col-md-10 col-sm-12 col-xs-12 offset-md-1 offset-lg-2">-->
<!--                <div class="card shadow sm">-->
<!--                  <div class="card-body">-->
<!--                    <form action="javascript:void(0)" @submit="update" class="row text-start" enctype="multipart/form-data" method="post">-->
<!--                      <div class="form-group col-12 pb-2">-->
<!--                        <div class="row m-0 p-0">-->
<!--                          <div class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">-->
<!--                            <div v-for="(hostBooking, index) in hostBookings">-->
<!--                              {{ hostBooking }}-->
<!--                            </div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                      <div class="col-12 mb-2 text-center">-->
<!--&lt;!&ndash;                        <button type="submit" :disabled="processing" class="btn btn-primary btn-block">&ndash;&gt;-->
<!--&lt;!&ndash;                          {{ processing ? "Please wait" : "Update" }}&ndash;&gt;-->
<!--&lt;!&ndash;                        </button>&ndash;&gt;-->
<!--                        <p v-if="this.validationMessage" class="required-info text-center">-->
<!--                          {{ this.validationMessage }} Please scroll up to see error messages.-->
<!--                        </p>-->
<!--                        <p v-if="this.showError && this.validationSuccess" class="success-info text-center">-->
<!--                          {{ this.validationSuccess }}-->
<!--                        </p>-->
<!--                      </div>-->

<!--                    </form>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Loader from "../Loader";
import DashboardBookingDetails from "./DashboardBookingDetails";
import ValidationErrors from "../ValidationErrors";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import router from "../../router";

export default {
  name:"DashboardBookings",
  components: {
    'ValidationErrors': ValidationErrors,
    'DashboardBookingDetails': DashboardBookingDetails,
    'Loader': Loader,
    'ConfirmationModal': ConfirmationModal
  },
  data(){
    return {
      user: (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) ? this.$store.state.auth.user : null,
      loading: true,
      processing:false,
      processActions: false,
      raiseIssue: false,
      modalData: false,
      hostCompletedBookings: null,
      guestCompletedBookings: null,
      hostBookings: null,
      guestBookings: null,
      pickedBookingId: null,
      newMessage: '',
      validationErrors: '',
      validationMessage: '',
      validationSuccess: '',
      showError: false,
      // Pagination
      pagination: false,
      paginationData: '',
      linksData: '',
      metaData: '',
      paginationType: 'hostBookings'
    }
  },
  async mounted() {
    this.processing = true;
    this.loading = true;
    // // Add tabs event listeners
    // let hostCompletedTab = document.getElementById('myCompletedBookingsTabUrl');
    // hostCompletedTab.addEventListener('click', this.getHostCompletedBookings);
    // let hostTab = document.getElementById('myBookingsTabUrl');
    // hostTab.addEventListener('click', this.getHostBookings);
    // let guestTab = document.getElementById('guestBookingsTabUrl');
    // guestTab.addEventListener('click', this.getGuestBookings);
    // let guestCompletedTab = document.getElementById('guestCompletedBookingsTabUrl');
    // guestCompletedTab.addEventListener('click', this.getGuestCompletedBookings);
    if (this.$store.state.auth.authenticated === true && this.$store.state.auth.user) {
      // await this.getGuestBookings();
      // await this.getHostCompletedBookings();
      // await this.getGuestCompletedBookings();
      await this.getHostBookings();

      if (this.hostBookings &&
          this.hostBookings.length > 0
      ) {
        this.loading = false;
        this.pagination = true;
        if (this.pagination) {
          this.calculatePagination();
        }
      } else {
        this.loading = false;
        this.pagination = false;
      }
    }
    this.loading = false;
    this.processing = false;
  },
  computed:{

  },
  async created() {
    // Create global events
    this.$root.$on('getModalData', (data) => this.getModalData(data));
    this.$root.$on('raisedIssue', (booking_id) => this.setRaisedIssue(booking_id));
  },
  beforeDestroy() {
    // Must destroy global trigger before we leave the page/component, otherwise it will layer on each other
    // and when we call it, it will trigger several times, breaking functionality
    this.$root.$off('getModalData');
    this.$root.$off('raisedIssue');
    // // Remove tab events
    // let hostCompletedTab = document.getElementById('myCompletedBookingsTabUrl');
    // hostCompletedTab.removeEventListener('click', this.getHostCompletedBookings);
    // let hostTab = document.getElementById('myBookingsTabUrl');
    // hostTab.removeEventListener('click', this.getHostBookings);
    // let guestTab = document.getElementById('guestBookingsTabUrl');
    // guestTab.removeEventListener('click', this.getGuestBookings);
    // let guestCompletedTab = document.getElementById('guestCompletedBookingsTabUrl');
    // guestCompletedTab.removeEventListener('click', this.getGuestCompletedBookings);
  },
  methods: {
    /* Pagination */
    async navigateTo(url, page = null, type) {
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.loading = true;
      if (page) {
        url = this.linksData.first.substring(0, this.linksData.first.lastIndexOf('=') + 1) + page;
      }

      let formData = new FormData();
      formData.append('host_id', this.$store.state.auth.user.id);

      await axios.post(url, formData).then(({data})=>{
        // I should really trigger setProperties from vuex
        this.metaData = data.meta;
        this.linksData = data.links;

        if (type === 'hostCompletedBookings') {
          this.hostCompletedBookings = data.data;
        }
        if (type === 'hostBookings') {
          this.hostBookings = data.data;
        }
        if (type === 'guestBookings') {
          this.guestBookings = data.data;
        }
        if (type === 'guestCompletedBookings') {
          this.guestCompletedBookings = data.data;
        }
      }).catch((response)=>{
        console.log(response);
      }).finally(()=> {
        this.processing = false
        this.loading = false;
      });

      if (type === 'hostCompletedBookings') {
        this.calculatePagination();
        // Toggle Tabs
        await this.toggleTabs('hostCompleted');
      }
      if (type === 'hostBookings') {
        this.calculatePagination();
        // Toggle Tabs
        await this.toggleTabs('host');
      }
      if (type === 'guestBookings') {
        this.calculatePagination();
        // Toggle Tabs
        await this.toggleTabs('guest');
      }
      if (type === 'guestCompletedBookings') {
        this.calculatePagination();
        // Toggle Tabs
        await this.toggleTabs('guestCompleted');
      }
    },
    previous() {
      if (this.linksData.prev) {
        this.navigateTo(this.linksData.prev)
      }
    },
    next() {
      if (this.linksData.next) {
        this.navigateTo(this.linksData.next)
      }
    },
    calculatePagination() {
      if(this.metaData && this.linksData) {

        // Current page any but total pages less than 10
        if (this.metaData.last_page < 10) {
          this.paginationData = this.metaData.last_page
        }

        // Current page 2 or less and last_page pages more than 10
        if (this.metaData.current_page <= 2 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 3 and last_page pages more than 10
        if (this.metaData.current_page === 3 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // Current page 4 and last_page pages more than 10
        if (this.metaData.current_page === 4 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, 4, 5, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 5 and last_page pages more than 10
        if (this.metaData.current_page === 5 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page 6 or more and last_page pages more than 10
        if (this.metaData.current_page >=6 && this.metaData.last_page >= 10) {
          this.paginationData = [1, 2, 3, '...', this.metaData.current_page - 1, this.metaData.current_page, this.metaData.current_page + 1, '...', this.metaData.last_page - 1, this.metaData.last_page]
        }

        // When page almost or equal last_page which is more than 10
        if (this.metaData.last_page >= 10 &&
            (this.metaData.current_page === this.metaData.last_page ||
                this.metaData.current_page === this.metaData.last_page - 1 ||
                this.metaData.current_page === this.metaData.last_page - 2
            )
        ) {
          this.paginationData = [1, 2, 3, '...', this.metaData.last_page - 3, this.metaData.last_page - 2, this.metaData.last_page - 1, this.metaData.last_page]
        }
      }
    },
    /* End Pagination */

    getModalData(data) {
      this.modalData = data;
    },
    setPickedBookingId(bookingId) {
      this.pickedBookingId = bookingId;
    },
    openAdDetails(id) {
      // Open propDetails (AdDetails.vue) to show specific favourite bookmark
      this.$root.$emit('openPropDetailsGlobal', id);
    },
    openAdServiceDetails(id) {
      if (id !== null) {
        let routeData = this.$router.resolve({path: `/ad-service/${id}`});
        window.open(routeData.href, '_blank');
      }
    },
    sendModal(bookingData, adType, booking = null, modalType, reviewType = null) {
      let type = null;
      let advertId = null;

      if (adType === 'appoint') {
        type = 'Service';
        advertId = bookingData.service_id;
      } else {
        type = 'Property';
        advertId = bookingData.property_id;
      }

      let data = {
        adDetails: bookingData,
        toUserId: bookingData.host_id,
        fromUserId: bookingData.guest.id,
        adId: advertId,
        adType: type,
        reviewType: reviewType ? reviewType : null,
        booking: booking,
        message: ""
      };

      this.$root.$emit('openModal', data, modalType);
    },
    sendRaiseIssueModal(bookingData, adType, modalType) {
      let type = null;
      let advertId = null;
      let options = null;

      if (adType === 'appoint') {
        type = 'Service';
        advertId = bookingData.service_id;
        options = {
          title: 'Issue type',
          options: [
            'Service provider did not show up and did not contact me',
            'Service provider is a scammer',
            'Service provided does not match what was advertised',
            'No contact with the Service Provider',
            'Other'
          ]
        }
      } else {
        type = 'Property';
        advertId = bookingData.property_id;
        options = {
          title: 'Issue type',
          options: [
            'Premises not as advertised',
            'Premises seem to not belong to the Host',
            'Premises do not exist',
            'No contact with the Host, cannot access premises',
            'Other'
          ]
        }
      }

      let data = {
        adDetails: bookingData,
        bookingId: bookingData.id,
        adId: advertId,
        adType: type,
        message: null,
        options: options
      };
      this.$root.$emit('openModal', data, modalType);
    },
    sendReviewModal(adDetails, guestId = null, reviewType, bookingType, modalType) {
      // Neighbourhood Necessary data:
      // adId, propertyDetails(address), reviewType (neighbourhood)
      // Service Necessary data:
      // adId, serviceDetails, reviewType (service), toUserId (service provider id), serviceOccupationId
      // Host Necessary data:
      // adId, propertyDetails, reviewType (host), toUserId (host id)
      // Guest Necessary data:
      // adId, propertyDetails, reviewType (guest), toUserId (guest id)
      let advertId = null;
      let details = null;
      let type = null;
      if (bookingType === 'reserve') {
        advertId = adDetails.property.id;
        details = adDetails.property;
      }
      if (bookingType === 'view') {
        advertId = adDetails.property.id;
        details = adDetails.property;
      }
      if (bookingType === 'appoint') {
        advertId = adDetails.service.id;
        details = adDetails.service;
        // For host we change to service, guest stays as guest
        if (reviewType === 'host') {
          reviewType = 'service';
        }
      }
      let data = {
        adId: advertId,
        adDetails: details,
        toUserId: guestId,
        reviewType: reviewType
      };
      this.$root.$emit('openModal', data, modalType);
    },
    checkReviewDate(booking) {
      // We give 2 weeks to leave review
      // Property (host & guest)
      if (booking && booking.booking_type === 'reserve') {
        let today = this.moment();
        let endDate = this.moment(booking.booking_end_date);

        let days = today.diff(endDate, 'days');
        if (days <= 7) {
          return true;
        } else {
          return false;
        }
      }
      // Service (service provider)
      if (booking && booking.booking_type === 'appoint') {
        let today = this.moment();
        let endDate = this.moment(booking.viewing_date);

        let days = today.diff(endDate, 'days');
        if (days <= 7) {
          return true;
        } else {
          return false;
        }
      }
    },
    checkCancelDate(booking) {
      if (booking && booking.booking_type === 'reserve') {
        let today = this.moment();
        let startDate = this.moment(booking.booking_start_date);

        if (today.isSameOrAfter(startDate)) {
          return false;
        } else {
          return true;
        }
      }
      // Service (service provider)
      if (booking && (booking.booking_type === 'appoint' || booking.booking_type === 'view')) {
        let today = this.moment();
        let startDate = this.moment(booking.viewing_date);

        if (today.isSameOrAfter(startDate)) {
          return false;
        } else {
          return true;
        }
      }
    },
    scrollToView(id) {
      const timeout = setTimeout(() => {
        let el = document.getElementById(id);
        let rect = el.getBoundingClientRect();
        let height = el.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: height - 200, behavior: 'smooth' });
        clearTimeout(timeout);
      }, 500);
    },
    setUserDefaultImg(e) {
      e.target.src = require(`@/assets/no-user-image.png`);
    },
    showRaiseIssueButton(booking) {
      if (booking.status === 'Confirmed' && (booking.booking_type === 'view' || booking.booking_type === 'appoint')) {
        // Calculate days
        let today = this.moment();
        let viewingDateBehind1Day = this.moment.utc(booking.viewing_date).subtract(1, 'days');
        let viewingDateAhead3Days = this.moment.utc(booking.viewing_date).add(3, 'days');
        // (Status Confirmed, Viewing/Appointment and Viewing Date -1 Day till Viewing Date + 3 Days)
        if (today.isSameOrAfter(viewingDateBehind1Day) && today.isSameOrBefore(viewingDateAhead3Days)) {
          return true;
        }
      }
      if (booking.status === 'Paid' && booking.booking_type === 'reserve') {
        // Calculate days
        let today = this.moment();
        let bookingEndDateAhead3Days = this.moment.utc(booking.booking_end_date).add(3, 'days');
        let bookingStartDate = this.moment.utc(booking.booking_start_date);
        // (Status Paid, Reservation and from Booking Start Date till Booking End Date + 3 days)
        if (today.isSameOrAfter(bookingStartDate) && today.isSameOrBefore(bookingEndDateAhead3Days)) {
          return true;
        }
      }
      return false;
    },
    setRaisedIssue(booking_id) {
      // hostBookings
      for (let i = 0; i < this.hostBookings.length; i++) {
        if (this.hostBookings[i].id === booking_id) {
          this.hostBookings[i].status = 'Raised Issue';
          this.hostBookings[i].is_issue_raised = true;
        }
      }
    },
    async toggleTabs(tabType) {

      // Get tab and tab content
      // Host
      let tabHost = document.getElementById('myBookingsTabUrl');
      let tabHostContent = document.getElementById('myBookings');
      // Host Completed
      let tabHostCompleted = document.getElementById('myCompletedBookingsTabUrl');
      let tabHostCompletedContent = document.getElementById('myCompletedBookings');
      // Guest
      let tabGuest = document.getElementById('guestBookingsTabUrl');
      let tabGuestContent = document.getElementById('guestBookings');
      // Guest Completed
      let tabGuestCompleted = document.getElementById('guestCompletedBookingsTabUrl');
      let tabGuestCompletedContent = document.getElementById('guestCompletedBookings');

      // Deactivate tabs
      if (tabHostContent && tabHostContent.classList.contains('active')) {
        tabHostContent.classList.remove('active');
        tabHostContent.classList.remove('show');
      }
      if (tabHost && tabHost.classList.contains('active')) {
        tabHost.classList.remove('active');
      }
      if (tabHostCompletedContent && tabHostCompletedContent.classList.contains('active')) {
        tabHostCompletedContent.classList.remove('active');
        tabHostCompletedContent.classList.remove('show');
      }
      if (tabHostCompleted && tabHostCompleted.classList.contains('active')) {
        tabHostCompleted.classList.remove('active');
      }
      if (tabGuest && tabGuest.classList.contains('active')) {
        tabGuestContent.classList.remove('active');
        tabGuestContent.classList.remove('show');
      }
      if (tabGuest && tabGuest.classList.contains('active')) {
        tabGuest.classList.remove('active');
      }
      if (tabGuestCompletedContent && tabGuestCompletedContent.classList.contains('active')) {
        tabGuestCompletedContent.classList.remove('active');
        tabGuestCompletedContent.classList.remove('show');
      }
      if (tabGuestCompleted && tabGuestCompleted.classList.contains('active')) {
        tabGuestCompleted.classList.remove('active');
      }

      if (tabType === 'guest' && tabGuest && tabGuestContent) {
        tabGuest.classList.add('active');
        tabGuestContent.classList.add('show');
        tabGuestContent.classList.add('active');
      }

      if (tabType === 'guestCompleted' && tabGuestCompleted && tabGuestCompletedContent) {
        tabGuestCompleted.classList.add('active');
        tabGuestCompletedContent.classList.add('show');
        tabGuestCompletedContent.classList.add('active');
      }

      if (tabType === 'host' && tabHost && tabHostContent) {
        tabHost.classList.add('active');
        tabHostContent.classList.add('show');
        tabHostContent.classList.add('active');
      }

      if (tabType === 'hostCompleted' && tabHostCompleted && tabHostCompletedContent) {
        tabHostCompleted.classList.add('active');
        tabHostCompletedContent.classList.add('show');
        tabHostCompletedContent.classList.add('active');
      }

    },
    async getHostCompletedBookings() {
      this.processing = true;
      this.loading = true;
      if (!this.$store.state.auth.user || this.$store.state.auth.user.id === null) {
        this.loading = false;
        this.processing = false;
        return;
      }

      let formData = new FormData();
      formData.append('host_id', this.$store.state.auth.user.id);

      await axios.post('api/host-bookings-completed', formData).then(async ({data}) => {
        this.showError = false;
        this.validationSuccess = null;
        this.validationMessage = '';
        this.validationErrors = '';
        this.hostCompletedBookings = data.data;
        this.metaData = data.meta;
        this.linksData = data.links;
        this.paginationType = 'hostCompletedBookings';

        // Calculate Pagination
        if (this.hostCompletedBookings &&
            this.hostCompletedBookings.length > 0
        ) {
          this.pagination = true;
          if (this.pagination) {
            this.calculatePagination();
          }
        } else {
          this.pagination = false;
        }
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.loading = false;
          this.processing = false;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
        }
      }).finally(() => {
        this.loading = false;
        this.processing = false;
      });
      // Toggle Tabs
      await this.toggleTabs('hostCompleted');
    },
    async getHostBookings() {
      this.processing = true;
      this.loading = true;
      if (!this.$store.state.auth.user || this.$store.state.auth.user.id === null) {
        this.loading = false;
        this.processing = false;
        return;
      }

      let formData = new FormData();
      formData.append('host_id', this.$store.state.auth.user.id);

      await axios.post('api/host-bookings', formData).then(async ({data}) => {
        this.showError = false;
        this.validationSuccess = null;
        this.validationMessage = '';
        this.validationErrors = '';
        this.hostBookings = data.data;
        this.metaData = data.meta;
        this.linksData = data.links;
        this.paginationType = 'hostBookings';

        // Calculate Pagination
        if (this.hostBookings &&
            this.hostBookings.length > 0
        ) {
          this.pagination = true;
          if (this.pagination) {
            this.calculatePagination();
          }
        } else {
          this.pagination = false;
        }
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.loading = false;
          this.processing = false;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
        }
      }).finally(() => {
        this.loading = false;
        this.processing = false;
      });
      // Toggle Tabs
      await this.toggleTabs('host');
    },
    async getGuestBookings() {
      this.processing = true;
      this.loading = true;

      if (!this.$store.state.auth.user || this.$store.state.auth.user.id === null) {
        this.loading = false;
        this.processing = false;
        return;
      }

      let formData = new FormData();
      formData.append('host_id', this.$store.state.auth.user.id);

      await axios.post('api/guest-bookings', formData).then(async ({data}) => {
        this.showError = false;
        this.validationSuccess = null;
        this.validationMessage = '';
        this.validationErrors = '';
        this.guestBookings = data.data;
        this.metaData = data.meta;
        this.linksData = data.links;
        this.paginationType = 'guestBookings';

        // Calculate Pagination
        if (this.guestBookings &&
            this.guestBookings.length > 0
        ) {
          this.pagination = true;
          if (this.pagination) {
            this.calculatePagination();
          }
        } else {
          this.pagination = false;
        }
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.loading = false;
          this.processing = false;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
        }
      }).finally(() => {
        this.loading = false;
        this.processing = false;
      });
      // Toggle Tabs
      await this.toggleTabs('guest');
    },
    async getGuestCompletedBookings() {
      this.processing = true;
      this.loading = true;
      if (!this.$store.state.auth.user || this.$store.state.auth.user.id === null) {
        this.loading = false;
        this.processing = false;
        return;
      }

      let formData = new FormData();
      formData.append('host_id', this.$store.state.auth.user.id);

      await axios.post('api/guest-bookings-completed', formData).then(async ({data}) => {
        this.showError = false;
        this.validationSuccess = null;
        this.validationMessage = '';
        this.validationErrors = '';
        this.guestCompletedBookings = data.data;
        this.metaData = data.meta;
        this.linksData = data.links;
        this.paginationType = 'guestCompletedBookings';

        // Calculate Pagination
        if (this.guestCompletedBookings &&
            this.guestCompletedBookings.length > 0
        ) {
          this.pagination = true;
          if (this.pagination) {
            this.calculatePagination();
          }
        } else {
          this.pagination = false;
        }
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.loading = false;
          this.processing = false;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
        }
      }).finally(() => {
        this.loading = false;
        this.processing = false;
      });
      // Toggle tabs
      await this.toggleTabs('guestCompleted');
    },
    convertNumberToCommas(num) {
      if (num && num !== '' && num !== null) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    // convertNumberToCommas Fixed 2 (for precise price calculations)
    convertNumberToCommas2Fixed(num) {
      if (num && num !== '' && num !== null) {
        let no = num.toFixed(2);
        return no.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return 0;
      }
    },
    // Callback is used for confirm/cancel/decline modals to pass either true or false from modal and allocate it to correct function name
    async callback(fnName, bool, passData) {
      // If returned TRUE we proceed with the function
      if (bool) {
        await this[fnName](passData, bool);
      }
    },
    async confirmBooking(booking, bool) {
      if (bool) {
        this.processActions = true;

        if ('id' in booking === false || (!this.$store.state.auth.user || !this.$store.state.auth.user.id)) {
          // Here we need to show error
          return;
        }

        let formData = new FormData();

        formData.append('booking', JSON.stringify(booking));
        formData.append('user_id', this.$store.state.auth.user.id);

        await axios.post('api/confirm-booking/' + booking.id, formData).then(async ({data}) => {
          this.showError = false;
          this.validationSuccess = null;
          this.validationMessage = '';
          this.validationErrors = '';

          // Trigger Send chat message to guest
          await this.sendNewMessage(booking, 'confirm', this.$store.state.auth.user.id);

          // Update booking front-end wise, so we dont see 'confirm' button anymore
          booking['status'] = 'Confirmed';
          for (let i = 0; i < this.guestBookings.length; i++) {
            if (this.guestBookings[i].id === booking.id) {
              this.$set(this.guestBookings, i, booking);
            }
          }

          this.processActions = false;

        }).catch(({response})=>{
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            this.loading = false;
            this.processing = false;
            this.processActions = false;
            const timeout = setTimeout(() => {
              this.validationErrors = '';
              clearTimeout(timeout);
            }, 3000)
          }
        }).finally(() => {
          this.loading = false;
          this.processing = false;
          this.processActions = false;
        })

      } else {
        // Prompt confirmation modal

        let data = {
          data: null,
          title: 'Confirm Booking',
          message: 'Are you sure you want to confirm this booking?',
          okButton: 'Confirm',
          cancelButton: 'Go Back',
          allowCustomMsg: false
        };

        await this.$root.$emit('openModal', data, 'Confirmation', 'confirmBooking', booking, (fnName, bool, passData) => this.callback(fnName, bool, passData));
      }

    },
    async declineBooking(booking, bool) {
      if (bool) {
        this.processActions = true;

        if ('id' in booking === false || (!this.$store.state.auth.user || !this.$store.state.auth.user.id)) {
          // Here we need to show error
          return;
        }

        let formData = new FormData();

        formData.append('booking', JSON.stringify(booking));
        formData.append('user_id', this.$store.state.auth.user.id);

        await axios.post('api/decline-booking/' + booking.id, formData).then(async ({data}) => {
          this.showError = false;
          this.validationSuccess = null;
          this.validationMessage = '';
          this.validationErrors = '';

          // Trigger Send chat message to guest
          await this.sendNewMessage(booking, 'decline', this.$store.state.auth.user.id);

          // Update booking front-end wise, so we dont see some of the buttons anymore
          booking['status'] = 'Declined';
          for (let i = 0; i < this.guestBookings.length; i++) {
            if (this.guestBookings[i].id === booking.id) {
              this.$set(this.guestBookings, i, booking);
            }
          }

          this.processActions = false;

        }).catch(({response})=>{
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            this.loading = false;
            this.processing = false;
            this.processActions = false;
            const timeout = setTimeout(() => {
              this.validationErrors = '';
              clearTimeout(timeout);
            }, 3000)
          }
        }).finally(() => {
          this.loading = false;
          this.processing = false;
          this.processActions = false;
        })

      } else {
        // Prompt confirmation modal

        let data = {
          data: null,
          title: 'Decline Booking',
          message: 'Are you sure you want to decline this booking?',
          okButton: 'Confirm',
          cancelButton: 'Go Back',
          allowCustomMsg: true
        };

        await this.$root.$emit('openModal', data, 'Confirmation', 'declineBooking', booking, (fnName, bool, passData) => this.callback(fnName, bool, passData));
      }
    },
    async cancelBooking(booking, bool) {
      if (bool) {
        this.processActions = true;

        if ('id' in booking === false || (!this.$store.state.auth.user || !this.$store.state.auth.user.id)) {
          // Here we need to show error
          return;
        }

        let formData = new FormData();

        formData.append('booking', JSON.stringify(booking));
        formData.append('user_id', this.$store.state.auth.user.id);
        formData.append('today', this.moment());

        await axios.post('api/cancel-booking/' + booking.id, formData).then(async ({data}) => {
          this.showError = false;
          this.validationSuccess = null;
          this.validationMessage = '';
          this.validationErrors = '';

          // Trigger Send chat message to host and guest
          await this.sendNewMessage(booking, 'cancel', this.$store.state.auth.user.id);

          // Update booking front-end wise, so we dont see some of the buttons anymore
          booking['status'] = 'Cancelled';
          for (let i = 0; i < this.guestBookings.length; i++) {
            if (this.guestBookings[i].id === booking.id) {
              this.$set(this.guestBookings, i, booking);
            }
          }

          this.processActions = false;

        }).catch(({response})=>{
          if (response && response.data && response.data.errors) {
            this.validationErrors = response.data.errors;
            this.loading = false;
            this.processing = false;
            this.processActions = false;
            const timeout = setTimeout(() => {
              this.validationErrors = '';
              clearTimeout(timeout);
            }, 3000)
          }
        }).finally(() => {
          this.loading = false;
          this.processing = false;
          this.processActions = false;
        })

      } else {
        // Prompt confirmation modal

        let data = {
          data: null,
          title: 'Cancel Booking',
          message: 'Are you sure you want to cancel this booking?',
          okButton: 'Confirm',
          cancelButton: 'Go Back',
          allowCustomMsg: true
        };

        await this.$root.$emit('openModal', data, 'Confirmation', 'cancelBooking', booking, (fnName, bool, passData) => this.callback(fnName, bool, passData));
      }
    },
    async sendNewMessage(booking = null, action = null, sender = null, receiver = null) {
      if (this.user === null) {
        return;
      }

      this.processing = true;

      // message type
      let messageType = null;
      // For Book Viewing (Long-term Lets and Sale Ads)
      if (booking.booking_type === 'view') {
        switch(action) {
          case 'confirm':
            this.newMessage = 'This viewing is confirmed. Please check your email for more information';
            messageType = 'confirm_viewing';
            break;
          case 'decline':
            this.newMessage = 'This viewing is declined. Please check your email for more information';
            messageType = 'decline_viewing';
            break;
          case 'cancel':
            this.newMessage = 'This viewing is cancelled. Please check your email for more information';
            messageType = 'cancel_viewing';
            break;
          default:
            break;
        }
      }
      // For Book Reservation (Short-term Lets Ads)
      if (booking.booking_type === 'reserve') {
        switch(action) {
          case 'confirm':
            this.newMessage = 'This reservation is confirmed. Please check your email for more information';
            messageType = 'confirm_reservation';
            break;
          case 'decline':
            this.newMessage = 'This reservation is declined. Please check your email for more information';
            messageType = 'decline_reservation';
            break;
          case 'cancel':
            this.newMessage = 'This reservation is cancelled. Please check your email for more information';
            messageType = 'cancel_reservation';
            break;
          default:
            break;
        }
      }
      // For Book Appointment (Services Ads)
      if (booking.booking_type === 'appoint') {
        switch(action) {
          case 'confirm':
            this.newMessage = 'This appointment is confirmed. Please check your email for more information';
            messageType = 'confirm_appointment';
            break;
          case 'decline':
            this.newMessage = 'This appointment is declined. Please check your email for more information';
            messageType = 'decline_appointment';
            break;
          case 'cancel':
            this.newMessage = 'This appointment is cancelled. Please check your email for more information';
            messageType = 'cancel_appointment';
            break;
          default:
            break;
        }
      }
      // axios call with this.newMessage value (make sure you do validation in the BE)
      let formData = new FormData();

      formData.append('chat_room_id', null);
      formData.append('from_user_id', booking.user_id);
      formData.append('to_user_id', booking.host_id);
      formData.append('message', this.newMessage);
      formData.append('message_type', messageType);

      if (booking.property_id) {
        formData.append('property_id', booking.property_id);
      }
      if (booking.service_id) {
        formData.append('service_id', booking.service_id);
      }

      let chatRoomId = null;
      await axios.post('api/user/' + this.user.id + '/messages/new/' + chatRoomId, formData).then(({data}) => {
        // Clear message
        this.newMessage = '';
        // Trigger to refresh messages?
        this.validationMessage = "Your request was sent successfully!";
        this.showError = true;
        const timeout = setTimeout(() => {
          this.showError = false;
          this.validationSuccess = null;
          this.validationMessage = '';
          clearTimeout(timeout);
        }, 2000);
        this.validationErrors = null;
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.processing = false;
          const timeout = setTimeout(() => {
            this.validationErrors = '';
            clearTimeout(timeout);
          }, 3000)
          // this.validationMessage = response.data.message;
        }
        // alert(data.message)
      }).finally(() => {
        this.processing = false
      })
    },
  },
  watch: {
  }
}
</script>


<style scoped lang="scss">

/* start tooltip - popups info on buttons */
.tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 10px;
  border-radius: 6px;
  padding: 5px 5px;

  /* Position the tooltip */
  position: absolute;
  margin-left: 10px;
  z-index: 1000;
}

[data-tooltip]:hover .tooltiptext {
  visibility: visible;
  color: #fff;
}
/* end tooltip - popups info on buttons */

.dashboard-header {
  letter-spacing: 2px;
}

.pagination {
  cursor: pointer;
}

// Tabs
.dashboard-bookings {
  .nav-item .nav-link {
    color: #818080;
    &:hover {
      color: #3d3d3d;
    }
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #3d3d3d;
    background: transparent;
    position: relative;
  }
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active:first-child:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: inherit;
    background: linear-gradient(to left, rgba(128, 0, 128, 0.3), rgba(25, 181, 254, 0.3));
    top: 0;
    left: 0;
  }
}
// End Tabs

.booking-btn {
  border: 0;
  background-color: transparent;
  padding: 0.75rem 0.75rem;
}
.booking-details {
  border: 0;
  background-color: transparent;
  padding: 0.75rem 0.75rem;
}

.host-booking-header {
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1002;
  padding-top: 5px;
  margin: 5px 0 5px 0;
  top: 12vh;
}

.booking-list-header {
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  display: inline-block;
  width: 100%;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(255, 255, 255, 1);
  overflow-wrap: break-word !important;
}

.booking-loading {
  min-height: 300px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  display: inline-block;
  width: 100%;
  padding: .75rem .75rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(255, 255, 255, 1);
  overflow-wrap: break-word !important;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;

  .loader {
    top: 15vh !important;
  }

}

.actions-loader {
  .loader {
    top: unset;
    width: 25px;
    height: 25px;
  }
}

.booking-list-raised-issue {
  background-color: rgb(255, 243, 209, 1) !important;
  overflow-wrap: break-word !important;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

.booking-list-raised-issue:hover {
  cursor: pointer;
  background-color: rgba(252, 214, 112, 1) !important;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}


.booking-list {
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  display: inline-block;
  width: 100%;
  //padding: .75rem .75rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(255, 255, 255, 1);
  overflow-wrap: break-word !important;
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;

  .details-btn {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: auto;
    font-size: 9px;
    padding: 0;
    color: mediumpurple;
    background-color: white;
    border: 1px solid mediumpurple;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .details-btn:hover {
    color: white;
    border: 1px solid white !important;
    background-color: mediumpurple;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .raise-issue-btn {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: auto;
    font-size: 9px;
    padding: 0;
    color: darkorange;
    background-color: white;
    border: 1px solid darkorange;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .raise-issue-btn:hover {
    color: white;
    border: 1px solid white !important;
    background-color: darkorange;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .pay-for-booking-btn {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: auto;
    font-size: 9px;
    padding: 0;
    color: darkorange;
    background-color: white;
    border: 1px solid darkorange;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .pay-for-booking-btn:hover {
    color: white;
    border: 1px solid white !important;
    background-color: darkorange;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .accept-btn {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: auto;
    font-size: 9px;
    padding: 0;
    color: limegreen;
    background-color: white;
    border: 1px solid limegreen;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .accept-btn:hover {
    color: white;
    border: 1px solid white !important;
    background-color: limegreen;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .decline-btn {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: auto;
    font-size: 9px;
    padding: 0;
    color: #dc3545;
    background-color: white;
    border: 1px solid #dc3545;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .decline-btn:hover {
    color: white;
    border: 1px solid white !important;
    background-color: #dc3545;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .cancel-btn {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: auto;
    font-size: 9px;
    padding: 0;
    color: #dc3545;
    background-color: white;
    border: 1px solid #dc3545;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .cancel-btn:hover {
    color: white;
    border: 1px solid white !important;
    background-color: #dc3545;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .user-image {
    width: 25px;
    height: 25px;
    margin: auto;
    border-radius: 50%;
    border: 1px solid #9e9e9e;
  }

  .chat-btn {
    border: 0;
    background-color: transparent;
  }

  .review-btn {
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 2px !important;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .review-btn:hover {
    color: white;
    border: 1px solid white !important;
    background-color: dimgrey;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .add-review-btn {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: auto;
    font-size: 9px;
    padding: 0;
    color: dimgrey;
    background-color: white;
    border: 1px solid dimgrey;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

  .add-review-btn:hover {
    color: white;
    border: 1px solid white !important;
    background-color: dimgrey;
    transition: 0.5s;
    -moz-transition: 0.5s;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
  }

}

.star-icon path {
  stroke: #3d3d3d;
  stroke-width: 20;
}

.booking-list:hover {
  cursor: pointer;
  background-color: rgba(246, 246, 246, 1);
  transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {

}
@media screen and (max-width: 767px) {
  .booking-list-header {
    font-size: 8px;
  }
  .booking-list {
    font-size: 8px;
  }
}
@media screen and (min-width: 768px) {

}
@media screen and (min-width: 992px) {
  .booking-list-header {
    font-size: 12px;
  }
  .booking-list {
    font-size: 12px;
  }
}
@media screen and (min-width: 1200px) {

}
@media (max-height: 800px) and (min-width: 1200px) {

}
@media screen and (min-width: 1680px) {

}
@media (min-height: 1200px) and (min-width: 1600px) {

}
@media screen and (min-width: 1920px) {

}

</style>